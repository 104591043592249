<template>
  <b-sidebar
    id="test-api-sidebar"
    body-class="custom-scroll"
    v-model="showTestAPISideBar"
    aria-labelledby="Test API"
    aria-label="Test API"
    shadow
    bg-variant="white"
    :backdrop-variant="`dark`"
    no-close-on-esc
    no-close-on-backdrop
    lazy
    backdrop
    right
    width="70vw"
    @hidden="hiddenSidebar"
  >
    <template #header="{ hide }">
      <h3 class="font-21 theme-font-medium mb-0">Test API</h3>
      <div class="sidebar-header-close">
        <a
          href="javascript:void(0)"
          role="button"
          aria-labelledby="Test API title"
          @click="
            hide
            $emit('closeSideBar')
          "
          ><img src="../../../../assets/img/close.png" alt="close-tab"
        /></a>
      </div>
    </template>
    <template #default="{}">
      <b-row>
        <b-col sm="8">
          <h4 class="page-sub-header">Configuration</h4>
          <TestAPIForm :data="data" :widgetDetails="widgetDetails" @testApiModelData="testApiModelData($event)" />
        </b-col>
        <b-col sm="4">
          <h4 class="page-sub-header">Widget preview</h4>
          <WidgetPreviewWrapper v-if="addWidgetFormValidStatus && showWidgetPreview" :type="'test-api'" :payload="widgetPreviewPayload" />
          <div v-else>Please fill in all required fields to be able to preview this widget.</div>
        </b-col>
      </b-row>
    </template>
    <template #footer="{ hide }">
      <b-button
        type="button"
        v-activeBlur
        class="btn-h-44"
        variant="outline-secondary"
        @click="
          hide
          $emit('closeSideBar')
        "
        >Close</b-button
      >
    </template>
  </b-sidebar>
</template>
<script>
import { mapState } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
export default {
  name: 'TestAPISideBar',
  props: ['showTestAPISideBar', 'data', 'widgetDetails'],
  data() {
    return {
      showWidgetPreview: false,
      widgetPreviewPayload: null,
    }
  },
  components: {
    TestAPIForm: () => import('./TestAPIForm.vue'),
    WidgetPreviewWrapper: () => import('@/components/widgets/add-edit-widget/widget-preview/WidgetPreviewWrapper.vue'),
  },
  computed: {
    ...mapState({
      addWidgetFormValidStatus: (state) => state.widgets.addWidgetFormValidStatus,
    }),
  },
  methods: {
    testApiModelData(data) {
      if (data) {
        this.widgetPreviewPayload = cloneDeep({ ...this.widgetDetails, prevwAddressInput: data })
        if (this.widgetPreviewPayload.locationWidgetSettings) {
          this.widgetPreviewPayload.locationWidgetSettings.apiUrl = data.apiUrl
          this.widgetPreviewPayload.locationWidgetSettings.securityMethodId = data.securityMethodId
          this.widgetPreviewPayload.locationWidgetSettings.applySecurityMethod = data.testWithSecurityMethod
        }
        if (this.widgetPreviewPayload.integrationWidgetSettings) {
          this.widgetPreviewPayload.prevwIntegWdgtInput = data
          this.widgetPreviewPayload.integrationWidgetSettings.apiUrl = data.apiUrl
          this.widgetPreviewPayload.integrationWidgetSettings.securityMethodId = data.securityMethodId
          this.widgetPreviewPayload.integrationWidgetSettings.linkAccountDetails = data.linkAccountDetails
        }
        this.showWidgetPreview = false
        this.$nextTick(() => {
          this.showWidgetPreview = true
        })
      } else {
        this.showWidgetPreview = false
      }
    },
    hiddenSidebar() {
      this.showWidgetPreview = false
    },
  },
}
</script>
